
axios.defaults.timeout = 60000

// axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
  let data = config.params || config.data || {};
	if (data && !data.unLoading) {
		Vue.prototype.$loading();
	} else {
		delete data.unLoading;
	}
	return config
}, error => {
	Promise.reject(error)
});
axios.defaults.validateStatus = function (status) {
	return status >= 200 && status <= 500 // 返回状态码区间
}
axios.interceptors.response.use(res => {
	Vue.prototype.$loading(false);
	
	if (res) {
		if ((res.status != 200 || (res.data.code != 0 && res.data.code != 400000)) && res.config.type != "file") {
			if (res.data.code == 920 || res.data.code == 925) {
        try {
					
        } catch (e) {
        }
				return;
			}
			if (res.data.message || res.data.msg) {
				Vue.prototype.$message({ text: (res.data.message || res.data.msg) })
			}
			return Promise.reject('error');
			// return false;
		} else {
			return res.data;
		}
	}
	return Promise.reject('error');
}, error => {
	Vue.prototype.$loading(false);
	return Promise.reject('error');
});
export default axios
