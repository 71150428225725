
import router from './router'
import store from './store/index'
import request from '@/axios'
import { getCookie } from "@/utils/common"
if(process.env.NODE_ENV !== 'production'){
  let vConsole = require('../public/js/vconsole.min.js')
  new vConsole()
}
let lang = (getCookie("websiteLang") || "en");
store.commit('SET_LANGTYPE', lang);
request({
  url: "./config/language/"+lang+".json",
  method: 'get',
  type: "file",
}).then(res=>{
  store.commit('SET_LANG', res);
}).catch(e=>{
})

request({
  url: "./config/img/"+lang+".json",
  method: 'get',
  type: "file",
}).then(res=>{
  store.commit('SET_IMG', res);
}).catch(e=>{
})

router.beforeEach((to, from, next) => {
  Vue.prototype.$loading();
  next();
})
router.afterEach((to, from) => {  
  Vue.prototype.$loading(false);
  store.commit("SET_PAGENAV",to.meta.pageNav);
  store.commit("SET_CHECKGAMESTATUS",0);
  store.commit("SET_HEADERCOLOR",to.meta.headerColor || 0);
})