import MessageBox from '../packages/message-box/index.js'
import Message from '../packages/message/index.js'
import Loading from '../packages/loading/index.js'

function install(Vue){
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$message = Message;
  Vue.prototype.$loading = Loading;
}
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
export default {
  version: '1.0.0',
}