import Main from './main.vue';
const ShareConstructor = Vue.extend(Main);

let instance;

const Loading = function(options) {

    instance = new ShareConstructor({
      data: options
    });
    let loadingDom = document.getElementById('loading');
    if(options === undefined || options === true){
      if(loadingDom){
        loadingDom.style.display = 'block';
        return;
      }
      instance.visible = true;
    }else{      
      if(loadingDom){
        loadingDom.style.display = 'none';
      };
    }
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.close = function () {
      instance.visible = false;
    }
};
Loading.close = () => {
  instance.visible = false;
};


export default Loading;
