<template>
  <div id="loading" v-if="visible">
    <div>
      <!-- <img src="" alt="loading"> -->
      <div class="loading"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loading",
  data(){
    return {

    }
  }
}
</script>
<style lang="stylus" scoped>
#loading{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: $maxWidth;
  margin: 0 auto;
  z-index: 1000;
  font-size: $Fts5;
  background-color: rgba(0,0,0,.8);
  & > div{
    flex( row, nowrap, center, center); 
    width: 1.2rem;
    height: 1.2rem;
    background-color: rgba(255,255,255,.5);
    border-radius(.06rem);  
    margin: 35vh auto;
  }
}
.loading{
  width: .3rem;
  height: .3rem;
  border: .03rem #b2b2b2 solid;
  border-left: .03rem #eaeaea solid;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: .2s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: .2s;
  -webkit-animation: rotate 1s linear infinite;
  -moz-animation: rotate 1s linear infinite;
  -o-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
}
@-webkit-keyframes rotate{from{-webkit-transform: rotate(0deg)}
  to{-webkit-transform: rotate(359deg)}
}
@-moz-keyframes rotate{from{-moz-transform: rotate(0deg)}
  to{-moz-transform: rotate(359deg)}
}
@-o-keyframes rotate{from{-o-transform: rotate(0deg)}
  to{-o-transform: rotate(359deg)}
}
@keyframes rotate{from{transform: rotate(0deg)}
  to{transform: rotate(359deg)}
}
</style>