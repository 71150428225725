<template>
  <div class="message" v-show="show">
    <div class="main">
      <div class="content">
        <h2 v-show="title" class="omitWrap">{{ title }}</h2>
        <div v-if="content" class="gameContent">
          <h2>{{ content.type | contentTitle }}</h2>
          <div 
            class="bgImg gameIcon"
            :style="{backgroundImage:(content.img?'url('+ $imgPrefix + content.img +')':'')}">
          </div>
          <div class="contentText" v-show="content.type=='pre'">
            <p>We will notift you when</p>
            <p>{{ content.name }}</p>
            <p>is available for download</p>
          </div>
          <div class="contentText" v-show="content.type=='download'">
            <p>You’ve downloaded</p>
            <p>{{ content.name }}</p>
          </div>
          <div class="contentText" v-show="content.type=='follow'">
            <p>{{ content.name }} Official Account</p>
          </div>
        </div>
        <div 
          v-show="img"
          class="bgImg messageImg unbg"
          :style="{backgroundImage:'url('+ img +')'}">
        </div>
        <div class="text" v-show="text" :style="{'color': textStyle && textStyle.color?textStyle.color:''}">{{ text }}</div>
      </div>
      <div class="messageBtm">
        <div v-show="$data.$type == 'confirm'">
          <span 
            @click="close('rej')"
            v-preventReClick
            class="cancel"
            >{{ "NO" | buttonFilter(button) }}
          </span>
          <span 
            v-preventReClick
            class="primary"
            @click="close('res')"
            >{{ "OK" | buttonFilter(button) }}
          </span>
        </div>
        <div v-show="$data.$type == 'alert'">
          <span 
            v-preventReClick
            class="primary"
            @click="close('res')"
            >{{ "OK" | buttonFilter(button) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "messageBox",
  data () {
    return {
      show: false,
      diyihh: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('hashchange', this.close);
      this.show = this.visible;
    });
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.close);
  },
  filters: {
    contentTitle(val){
      let text = "";
      switch(val){
        case "pre": text = "Pre-registered"; break; 
        case "download": text = "Downloaded"; break; 
        case "follow": text = "Follow"; break; 
      };
      return text;
    },
    buttonFilter(val,obj){
      if(!obj){return val};
      let text = "";
      switch(val){
        case "NO": text = (obj.left || "NO") ;break;
        case "OK": text = ( obj.right || "OK") ;break;
      };
      return text;
    }
  },
  methods: {
    close(val){
      this.$nextTick(() => {
        this.show = false;
        let diyihh = false;
        if(!this.diyihh){
          diyihh = true;
        }
        if(val == "rej" && this.unLeft){
          diyihh = true;
        }
        switch(val){
          case 'res': this.res(diyihh);break;
          case 'rej': this.rej(diyihh);break;
        }
      })
    },
    attentionFn(){
      this.diyihh = !this.diyihh;
    }
  }
}
</script>
<style lang="stylus" scoped>
.message{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: $maxWidth;
  margin: 0 auto;
  z-index: 99;
  font-size: $Fts5;
  flex( row, nowrap, center, center);
  background-color: rgba(0,0,0,.8);
}
.main{
  max-width: 5rem;
  width: 84%;
  text-align: center;
  background-color: #fff;
  border-radius(.06rem);
  margin-bottom: .6rem;
}
.content{
  padding: .2rem;
}
h2{
  font-size: $Fts5;
  font-weight: normal;
  margin: .1rem 0;
}
.messageBtm{
  height: .56rem;
  line-height: .56rem;
  overflow: hidden;
  border-top: .01rem solid $CLGy1;
  & span:nth-child(2){
    box-sizing: border-box;
    border-left: .01rem solid $CLGy1; 
  }
}
.text,.diyihh{
  font-size: $Fts6;
  word-wrap break-word;
  color: $CFtGy3;
}
.text{
  line-height: .24rem;  
  margin: .1rem 0;
}
.icon{
  margin-right: .02rem;  
}
.diyihh{
  flex( row, nowrap, center, center);
}
.messageImg{
  width: .4rem;
  height: .4rem;
  margin: auto;
  margin-top: .2rem;
}
.messageBtm span{
  display: inline-block;
  width: 50%;
  height: .56rem;
  &.cancel{
    color: $CFtGy3;
  }
  &.primary{
    color: $CPrimary;  
  }
}
.TTKOAText{
  padding-top: .03rem;  
}
.gameContent{
  flex( column, nowrap, center, center);
  & h2{
    font-size: $Fts5;
    font-family: 'mainEnB';
  }
  & .contentText{
    margin-bottom: .06rem; 
  }
  & .gameIcon{
    width: .44rem;
    height: .44rem;
    border-radius(.06rem);
    margin: .06rem 0 .14rem 0;
  }
  & p{
    font-size: $Fts6;
    font-weight:400;
    line-height: .22rem;
  }
}
</style>