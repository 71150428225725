
let single = ["b","g","i","k","m","o","t"]

export function getCookie(key) {
  if (document.cookie.length > 0) {
    let cookies = document.cookie.split('; ');
    let cookieArr;
    for (let i = 0; i < cookies.length; i++) {
      cookieArr = cookies[i].split('=');
      if (cookieArr[0] == key) {
        return cookieArr[1];
      };
    };
  };
  return false;
};

export function setCookie(name,value,s) {
  var exp = new Date();
  exp.setTime(exp.getTime() + s*1000);
  document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
};

export function getQuery(name) {
  var url = location.hash;
  if (url.indexOf("?") != -1) {
    let  str = url.split("?")[1];
    let strs = str.split("&");
    for(var i = 0; i < strs.length; i ++) {
      if(strs[i].split("=")[0] == name){
        return unescape(strs[i].split("=")[1]);
      }
    }
  }
  return false;
};

export function isTTk(){
  let ttk = single[6] + single[5] + single[6] + single[5] + single[3];
  return !!navigator.userAgent.toLowerCase().includes(ttk);
}

export function isBotim() {
  // return true;
  return !!navigator.userAgent.toLowerCase().match(/botim/gi);
}

export function isValidTTk() {
  let ua = window.navigator.userAgent.toLowerCase();
  let versionCode = "v0.0.0"
  let uaValuesArr = ua.split(" ");
  let needCheckVersion = false;
  for (let uaIndex = 0; uaIndex < uaValuesArr.length; uaIndex++) {
    var uaValueItem = uaValuesArr[uaIndex];
    let b = single[6]+single[5]+single[6]+single[5]+single[3];
    if (uaValueItem.indexOf(b) >= 0) {
      needCheckVersion = true;
    }
    if (needCheckVersion && uaValueItem.match(/v\d{0,1}\.{0,1}\d{0,1}\.{0,1}\d{0,1}/)) {
      versionCode = uaValueItem;
    }
  }
  versionCode = versionCode.replace(/\.|v/g, "")*1;
  console.log("versionCode:" + versionCode);
  if(versionCode>=146){//145
    return true;
  } else {
    return false;
  }
}

export function getTTKVersion() {
  let ua = window.navigator.userAgent.toLowerCase();
  let versionCode = "v0.0.0"
  let uaValuesArr = ua.split(" ");
  let needCheckVersion = false;
  for (let uaIndex = 0; uaIndex < uaValuesArr.length; uaIndex++) {
    var uaValueItem = uaValuesArr[uaIndex];
    let b = single[6]+single[5]+single[6]+single[5]+single[3];
    if (uaValueItem.indexOf(b) >= 0) {
      needCheckVersion = true;
    }
    if (needCheckVersion && uaValueItem.match(/v\d{0,1}\.{0,1}\d{0,1}\.{0,1}\d{0,1}/)) {
      versionCode = uaValueItem;
    }
  }
  versionCode = versionCode.replace(/\.|v/g, "")*1;
  return versionCode;
}

export function isAndroid() {
  var u = navigator.userAgent;
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 || u.indexOf("android") > -1 || u.indexOf("linux") > -1) { //安卓
    return true;
  }
  return false;
}

export function isPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

export function isIOS() {
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios
  return isiOS;
}

export function platform() {
  if (isAndroid()) {
    return 1;
  } else if (isPC()) {
    return 0;
  } else {
    return 0;
  }
}
