// import Qs from 'qs' 
import request from '@/axios'
import { api } from "./api"

export function requestGet(url,obj,headers){
  if(!obj)obj = {};
  let params = "?";
  let _params = {}
  for(let i in obj){
    if(i == "unLoading"){
      _params = {
        unLoading: true,
      }
      continue;
    }
    if(obj[i] instanceof Array){
      for(let j = 0; j<obj[i].length;j++ ){
        params += (i+"="+obj[i][j]+"&");
      }
    }else{
      params += (i+"="+obj[i]+"&");
    }
  }
  params = params.slice(0,params.length-1);
	return request({
		url: "/api/web/" + api[url] + params,
    method: 'get',
    params: _params,
		headers: headers,
	})
}
export function requestPost(url,obj){
	return request({
		url: "/api/web/" + api[url],
		method: 'post',
		// data: Qs.stringify(obj),
		data: obj
	})
}


export function requestPostAll(url,obj){
	return request({
		url: url,
		method: 'post',
		// data: Qs.stringify(obj),
		data: obj
	})
}
