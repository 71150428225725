const getters = {
  langHeader: state => state.common.lang.header,
  langCommon: state => state.common.lang.common,
  langAboutUs: state => state.common.lang.aboutUs,
  langContactUs: state => state.common.lang.contactUs,
  langType: state => state.common.langType,
  screen: state => state.common.screen,
  headerColor: state => state.common.headerColor,
  pageNav: state => state.common.pageNav,
  checkGameStatus: state => state.common.checkGameStatus,
  headerImg: state => state.common.img.header,
  bannerImg: state => state.common.img.bannerImg,
  gamesImg: state => state.common.img.gamesImg,
  companyImg: state => state.common.img.companyImg,
  contactImg: state => state.common.img.contactImg,
  accountImg: state => state.common.img.accountImg,
  rechargeImg: state => state.common.img.rechargeImg,
}
export default getters