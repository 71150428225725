import { requestGet, requestPost, requestPostAll } from "@/api/function"
import { platform } from "@/utils/common"
const prototypeExpand = {
    install:function(Vue){
        Vue.prototype.$get = requestGet;
        Vue.prototype.$post = requestPost;
        Vue.prototype.$postAll = requestPostAll;
        Vue.prototype.$platform = platform(); 
    }
}

export default prototypeExpand