import store from '@/store/index'
import Main from './main.vue';
import merge from '@/lib/src/utils/merge';
const ShareConstructor = Vue.extend(Main);

let instance;

const MessageBox = function(options) {
  return new Promise((res, rej) => {

    instance = new ShareConstructor({
      data: options
    });
    instance.visible = true;
    instance.$mount();
    document.body.appendChild(instance.$el);
    var topVal = 0;
    topVal = (document.documentElement.scrollTop || document.body.scrollTop);
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.top = -topVal + 'px';
    instance.res = function (diyihh) {
      diyihhFn(diyihh,this)
      
      document.body.style.position = 'static';
      document.body.style.width = 'auto';
      try{
        window.scrollTo(topVal,topVal);
      }catch (e){

      }
      res()
      instance.visible = false;

    }
    instance.rej = function (diyihh) {
      document.body.style.position = 'static';
      document.body.style.width = 'auto';
      diyihhFn(diyihh,this)
      try{
        window.scrollTo(topVal,topVal);
      }catch (e){

      }
      rej()
      instance.visible = false
    }
  });
};
MessageBox.alert = (options)=>{
  return MessageBox(merge({
    $type: 'alert',
  }, options));
}
MessageBox.confirm = (options)=>{
  return MessageBox(merge({
    $type: 'confirm',
  }, options));
}


function diyihhFn(diyihh,that){
  if(diyihh) return;
}
export default MessageBox;
