
const common = {
  state: {
    lang: {},
    screen: 0,
    headerColor: 0,
    pageNav: 0,
    checkGameStatus: 0,
    img:{},
    langType: "en",
  },
  actions: {},
  mutations: {
    "SET_LANG": (state,active)=>{
      state.lang = active;
    },
    "SET_SCREEN": (state,active)=>{
      state.screen = active;
    },
    "SET_HEADERCOLOR": (state,active)=>{
      state.headerColor = active;
    },
    "SET_PAGENAV": (state,active)=>{
      state.pageNav = active;
    },
    "SET_CHECKGAMESTATUS": (state,active)=>{
      state.checkGameStatus = active;
    },
    "SET_IMG": (state,active)=>{
      state.img = active;
    },
    "SET_LANGTYPE": (state,active)=>{
      state.langType = active;
    },
  }
}
export default common
