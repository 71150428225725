const Home = () => import('@/views/Home')
const Games = () => import('@/views/Games/index')
const Game = () => import('@/views/Games/default')
const Company = () => import('@/views/Company')
const Contact = () => import('@/views/Contact')
const Account = () => import('@/views/Account')
const Recharge = () => import('@/views/Recharge')


const routes = [
  {
      path: "/",
      name: "index",
      redirect: "/home"
  },
  {
      path: "/home",
      name: "home",
      component: Home,
      meta:{
        
      }
  },
  {
      path: "/games",
      name: "games",
      component: Games,
      meta:{
        pageNav: 1,
      }
  },
  {
      path: "/game",
      name: "game",
      component: Game,
      meta:{
        pageNav: 1,
      }
  },
  {
      path: "/company",
      name: "company",
      component: Company,
      meta:{
        pageNav: 2,
      }
  },
  {
      path: "/contact",
      name: "contact",
      component: Contact,
      meta:{
        pageNav: 3,
        headerColor: 1,
      }
  },
  {
      path: "/account",
      name: "account",
      component: Account,
      meta:{
        pageNav: 4,
        headerColor: 1,
      }
  },
  {
      path: "/recharge",
      name: "recharge",
      component: Recharge,
      meta:{
        pageNav: 5,
        headerColor: 1,
      }
  },
 
]

export default new VueRouter({
  routes,
})