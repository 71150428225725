export default {
  install (Vue) {
    Vue.directive('preventReClick', {
      inserted (el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            el.style.pointerEvents = "none"
            setTimeout(() => {
              el.disabled = false
              el.style.pointerEvents = "auto"
            }, binding.value || 600)
          }else{
            setTimeout(() => {
              el.disabled = false
              el.style.pointerEvents = "auto"
            }, binding.value || 300)
          }
        })
      }
    })
  }
}