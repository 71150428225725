<template>
  <div 
    :class="[
      'header',
      'flex',
      {'active':headerColor && !screen},
      {'leaveGame':checkGameStatus==2},
      {'checkGame':checkGameStatus==1}]">
    <div 
      :class="['navIcon',{'active': navShowStatus===1}]" 
      @click="navIconFn"
      v-if="screen==1">
      ☰
    </div>
    <div :class="['navList', 'langEnI',{'navShow': navShowStatus===1},{'navHide': navShowStatus===2}]">
      <router-link tag="span" to="games">{{ langHeader.games }}</router-link>
      <router-link tag="span" to="company">{{ langHeader.company }}</router-link>
      <router-link tag="span" to="recharge">{{ langHeader.recharge }}</router-link>
      <router-link tag="span" to="contact">{{ langHeader.contact }}</router-link>
      <!-- <router-link tag="span" to="account">{{ langHeader.account }}</router-link> -->
    </div>
    <router-link to="home">
      <img 
        :src="logoUrl" />
    </router-link>
    <ul class="flex langEnI">
      <li 
        v-if="!screen"
        @click="checkGame" 
        @mouseenter="mouseenterFn"
        @mouseleave="mouseleaveFn"> 
        <span :class="{'check': pageNav==1}">
          {{ langHeader.games }}
        </span>
        <div 
          :class="['gameList','langEnI']"
          v-show="checkNav">
          <span :class="{'invalid':!this.scroll.left}"></span>
          <div>
            <div
              @click.stop="$router.push({path:'game',query:{id:item.games}})"
              :class="['game',{'enter':gameIndex===index},{'leave':gameIndex==='l'+index}]"
              v-for="(item,index) in headerImg"  
              :key="'game_'+index">
              <div 
                class="imgContent"
                @mouseenter="gameIndex=index"
                @mouseleave="gameIndex='l'+index">
                <img :src="item.url" alt="">
                <div class="gameShade">
                  <img :src="item.shadeUrl" alt="">
                </div>
              </div>
              <div>{{ item.text }}</div>
            </div>
          </div>
          <span :class="{'invalid':!this.scroll.right}"></span>
        </div>
      </li>
      <router-link tag="li" to="company" v-if="!screen">
        <span :class="{'check': pageNav==2}">{{ langHeader.company }}</span>
      </router-link>
      <router-link tag="li" to="recharge" v-if="!screen">
        <span :class="{'check': pageNav==5}">
          {{ langHeader.recharge }}
        </span>
      </router-link>
      <router-link tag="li" to="contact" v-if="!screen">
        <span :class="{'check': pageNav==3}">{{ langHeader.contact }}</span>
      </router-link>
      <!-- <router-link tag="li" to="account" v-if="!screen">
        <span :class="{'check': pageNav==4}">{{ langHeader.account }}</span>
      </router-link> -->
      <li 
        :class="['lang',{'check':checkLang == 1},{'leave':checkLang==2},{'langShow':checkLang==1},{'langHide':checkLang==2}]" 
        @click="checkLanguage">
        {{ langHeader.en }} 
        <a><img src="../../assets/img/en.png" alt=""></a>
        <ul>
          <li :class="{'active':this.langType == 'en'}" @click.stop="checkLangFn('en')">{{ langHeader.english }}</li>
          <!-- <li :class="{'active':this.langType == 'ar'}" @click.stop="checkLangFn('ar')">{{ langHeader.arabic }}</li> -->
        </ul>
      </li>
    </ul>
    
  </div>
</template>
<script>
import { setCookie } from "@/utils/common"
import request from '@/axios'
export default {
  data() {
    return {
      checkNav: 0,
      checkLang: false,
      gameIndex: false,
      navShowStatus: 0,
      scroll:{
        left: false,
        right: false,
      },
    };
  },
  computed: {
    ...Vuex.mapGetters([
      "langHeader", 
      "headerColor", 
      "screen", 
      "pageNav", 
      "checkGameStatus", 
      "headerImg",
      "langType"
    ]),
    logoUrl(){
      let url = require('@/assets/img/logo/logo-white.png');
      if(this.headerColor==1 && !this.screen){
        url = require('@/assets/img/logo/logo-black.png')
      }
      return url;
    }
  },
  watch: {
    $route(n,o){
      if(this.navShowStatus===1){
        this.navShowStatus = 2
      }else{
        this.navShowStatus = 0;
      }
    }
  },
  mounted(){
    
  },
  methods: {
    checkGame(){
      this.checkNav = 0;
      this.$store.commit("SET_CHECKGAMESTATUS",0);
      this.$store.commit("SET_HEADERCOLOR",this.$route.meta.headerColor);
      this.$router.push("/games");
    },
    mouseenterFn(){
      if(this.screen)return;
      this.checkLang = false;
      this.checkNav = 1;
      this.$store.commit("SET_CHECKGAMESTATUS",1);
      this.$store.commit("SET_HEADERCOLOR",1);
      setTimeout(()=>{
        this.checkNav = 1;
      },80)
    },
    mouseleaveFn(){
      if(this.screen)return;
      if(this.checkNav == 0)return;
      this.$store.commit("SET_CHECKGAMESTATUS",2);
      this.$store.commit("SET_HEADERCOLOR",this.$route.meta.headerColor);
      setTimeout(()=>{
        this.checkNav = 0;
      },80)
    },
    checkLanguage(){
      this.$store.commit("SET_CHECKGAMESTATUS",0);
      if(this.checkLang != 1){
        this.checkLang = 1;
      }else{
        this.checkLang = 2;
      }
    },
    checkLangFn(lang){
      this.checkLang = false;
      if(this.langType != lang){
        this.$store.commit('SET_LANGTYPE', lang);
        setCookie("websiteLang",lang)
        request({
          url: "./config/language/"+lang+".json",
          method: 'get',
          type: "file",
        }).then(res=>{
          this.$store.commit('SET_LANG', res);
        }).catch(e=>{
        })
        request({
          url: "./config/img/"+lang+".json",
          method: 'get',
          type: "file",
        }).then(res=>{
          console.log(res)
          this.$store.commit('SET_IMG', res);
        }).catch(e=>{
        })
      }
      
    },
    navIconFn(){
      if(this.navShowStatus===1){
        this.navShowStatus = 2
      }else{
        this.navShowStatus = 1;
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: .96rem;
  font-size: $Fts3;
  padding-left: .86rem;
  box-sizing: border-box;
  color: $Cf;
  >a {
    line-height: .96rem;
    & img{
      height: .55rem;
      vertical-align: middle;
    }
  }
  &.checkGame{
    background-color: rgba(255,255,255,.9);  
    animation: gamesShowKey 0.2s ease-in-out;
    -webkit-animation: gamesShowKey 0.2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
    overflow: hidden;
    // height: 2.6rem;
  }
  &.leaveGame{
    animation: gamesHideKey 0.2s ease-in-out;
    -webkit-animation: gamesHideKey 0.2s ease-in-out;
    animation-fill-mode: forwards;
    overflow: hidden;
    // animation-delay: 0.1s;
    // -webkit-animation-delay: 0.1s;
  }
}
.navIcon{
  font-size: $Ftm3;
  line-height: .7rem;  
  padding: 0 .22rem;
  &.active{
    color: $CLGy1;
  }
}
.navList{
  position: absolute;
  top: .7rem;  
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: $Cf;
  color: $C0;
  height: 0;
  overflow: hidden;
  & span{
    font-size: $Fts2;
    display: block;
    border-bottom: 0.01rem solid $CFtGy2;
    height: .52rem;
    line-height: .52rem;
    text-align: center;
  }
  &.navShow{
    animation: navShowKey 0.3s ease-in-out;
    -webkit-animation: navShowKey 0.3s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;    
  }
  &.navHide{
    animation: navHideKey 0.3s ease-in-out;
    -webkit-animation: navHideKey 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }
}
ul{
  height: .96rem;
  line-height: .96rem;
  & li{
    padding: 0 .22rem;
    cursor: pointer;
    text-align: center;
    & span{
      line-height: .2rem;
      display: inline-block;
      &::after{
        content: "";
        display: block;
        height: .016rem;
        width:0;
        margin-top: .04rem;
        background-color: $CLGy1;
        border-radius(.01rem);
      }   
      &.check{
        &::after{
          width: 100%;
        }
      }
    }  
    & span:hover{
      &:after{
        animation: borderShowKey 0.3s ease-in-out;
        -webkit-animation: borderShowKey 0.3s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
    }
  }
  & .lang{
    position: relative;
    vertical-align: middle;
    line-height: .96rem;
    padding: 0 .22rem;
    & a {
      width: .2rem;
      & img{
        width: .2rem;
      }
    }  
    & ul{
      position: absolute;
      bottom: -.6rem;
      width: 100%;
      overflow: hidden;
      left: 0;
      height: 0;
      top: .8rem;
      z-index: 2;
      & li{
        background-color: rgba(243,128,49,.9);
        height: .6rem;
        line-height: .6rem;
        margin-left: 100%;
        &.active{
          background-color: rgba(243,128,49,1);
        }
      }
    }
    &.check{
      & ul{
        height: 1.96rem;
      }
      & a img{
        animation: checkShowKey 0.2s ease-in-out;
        -webkit-animation: checkShowKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
    }
    
    &.leave{
      & ul{
        background-color: none;
        height: 1.96rem;
      }
      & a img{
        animation: checkLeaveKey 0.2s ease-in-out;
        -webkit-animation: checkLeaveKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
      }
    }
    &.langShow{
      &:after{
        content: "";
        position: absolute;
        height: .8rem;
        right: 0;
        z-index: -1;
        background-color: rgba(243,128,49,1);
        animation: enBgShowKey 0.2s ease-in-out;
        -webkit-animation: enBgShowKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }      
      & ul{
        & li{
          &:first-child{
            animation: enShowKey 0.2s ease-in-out;
            -webkit-animation: enShowKey 0.2s ease-in-out;
            animation-fill-mode: forwards;
            animation-delay: 0.25s;
            -webkit-animation-delay: 0.25s;
          }  
          &:last-child{
            animation: arShowKey 0.2s ease-in-out;
            -webkit-animation: arShowKey 0.2s ease-in-out;
            animation-fill-mode: forwards;
            animation-delay: 0.35s;
            -webkit-animation-delay: 0.35s;
          }  
        }
      }
    }
    &.langHide{
      &:after{
        content: "";
        position: absolute;
        height: .8rem;
        right: 0;
        z-index: -1;
        background-color: rgba(243,128,49,1);
        animation: enBgHideKey 0.5s ease-in-out;
        -webkit-animation: enBgHideKey 0.5s ease-in-out;
        animation-fill-mode: forwards;
      }  
      & ul{
        & li{
          &:first-child{
            animation: enHideKey 0.35s ease-in-out;
            -webkit-animation: enHideKey 0.35s ease-in-out;
            animation-fill-mode: forwards;
          }  
          &:last-child{
            animation: arHideKey 0.2s ease-in-out;
            -webkit-animation: arHideKey 0.2s ease-in-out;
            animation-fill-mode: forwards;
          }  
        }
      }
    }
  }
}


.header.active{
  color: $C0;
  .lang a img{
    filter: brightness(0);
  }
}
.gameList{
  position: absolute;  
  width: 100%;
  height: 2.7rem;
  top: .96rem;
  left: 0;
  padding: 0 1.12rem;
  box-sizing: border-box;
  flex( row, nowrap, space-between, center);
  & > span{
    width: .15rem;
    height: .15rem
    border-left: .02rem solid $C0;
    border-top: .02rem solid $C0;
    cursor: pointer;
    &.invalid{
      border-color: rgba(0,0,0,.3);
    }
    &:first-child{     
      tranform(rotate(-45deg));
    }
    &:last-child{     
      tranform(rotate(135deg));
    }
  }
  & > div{
    width: 86%;
    height: 100%;
    flex(row, nowrap, left, center);
  }
  & .game{
    cursor: pointer;
    font-size: $Fts4;
    width: 1.6rem;
    text-align: center;
    position: relative;
    margin: 0 .35rem;
    & div{
      width: 1.6rem;
      line-height: .16rem;
      height: .32rem;
      text-align: left;
    }
    & .imgContent{
      width: 1.6rem;  
      height: 2.04rem;
      margin: .6rem 0 .1rem;
      overflow: hidden;
      & img{
        width: 100%;  
      }
    }
    & .gameShade{
      position: absolute;
      top:.6rem;
      height: 2.04rem;
      left: 0;
      width: 1.6rem;
      background-color: rgba(0,0,0,.6);
      line-height: 2.04rem;
      text-align: center;
      // display: none;
      opacity: 0;
      & img{
        width 60%;  
      }
    }
    &.enter{
      & .imgContent img {
        animation: gameScaleKey 0.2s ease-in-out;
        -webkit-animation: gameScaleKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
      & .gameShade{
        animation: gameShadeEnterKey 0.2s ease-in-out;
        -webkit-animation: gameShadeEnterKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
    }
    &.leave{
      & .imgContent img {
        animation: gameScaleLeaveKey 0.2s ease-in-out;
        -webkit-animation: gameScaleLeaveKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
      }
      & .gameShade{
        animation: gameShadeLeaveKey 0.2s ease-in-out;
        -webkit-animation: gameShadeLeaveKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
          
      }
    }
  }

}

@media screen and (max-width: 980px){
  .header{
    font-size: $Fts5;
    padding-left: .1rem;
    position: static;
    height: .7rem;
    background-color: $C0;
    & > a{
      line-height: .7rem;
      & img{
        height: .34rem;  
      }  
    }
    & ul .lang{
      line-height: .7rem;      
      font-size: $Fts1;
      & ul{
        top: .7rem;
      }
      & a{
        & img{
          width: 40%;
        }   
      }
    }
  }
}

@keyframes borderShowKey {
  from {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes gamesShowKey {
  from {
    height: .6rem;
  }
  to {
    height: 4.3rem;
  }
}
@keyframes gamesHideKey {
  0% {
    height: 3.8rem;
    background-color: rgba(255,255,255,.9);
  }
  99% {
    height: .96rem;
    background-color: rgba(255,255,255,0.9);
  }
  100% {
    height: .96rem;
    background-color: rgba(255,255,255,0);
  }
}
@keyframes enBgShowKey {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes enBgHideKey {
  0% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  100% { 
    height: .8rem;
    width: 0;
  }
}
@keyframes checkShowKey {
  0% {
    tranform(rotate(0));
  }
  100% {
    tranform(rotate(180deg));
  }
}
@keyframes checkLeaveKey {
  0% {
    tranform(rotate(180deg));
  }
  100% {
    tranform(rotate(0));
  }
}
@keyframes checkHideKey {
  0% {
    tranform(rotate(135deg));
  }
  100% {
    tranform(rotate(-455deg));
  }
}
@keyframes enShowKey {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0%;
  }
}
@keyframes arShowKey {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0%;
  }
}
@keyframes enHideKey {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: 100%;
  }
}
@keyframes arHideKey {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: 100%;
  }
}
@keyframes gameScaleKey {
  0% {
    tranform(scale(1));
  }
  100% {
    tranform(scale(1.2));
  }
}
@keyframes gameScaleLeaveKey {
  0% {
    tranform(scale(1.2));
  }
  100% {
    tranform(scale(1));
  }
}
@keyframes gameShadeEnterKey {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes gameShadeLeaveKey {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes navShowKey {
  0% {
    height: 0;
  }
  100% {
    height: 2.12rem;
  }
}

@keyframes navHideKey {
  0% {
    height: 2.12rem;
  }
  100% {
    height: 0;
  }
}
</style>