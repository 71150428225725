<template>
  <div class="libMessage" v-if="show">
    <div :class="['messageContent',{'show':show}]">
      {{ $data.text }}
    </div>
  </div>
</template>
<script>
export default {
  name: "libMessage",
  data(){
    return{
      show: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('hashchange', this.close);
      this.show = this.visible;
      setTimeout(()=>{
        this.show = false;
        this.close()
      },2800)
    });
  },
}
</script>
<style lang="stylus" scoped>
.libMessage{
  position: fixed;
  width: 100%;
  margin: 40vh auto 0;
  z-index: 99;
  top: 0;
  font-size: $Fts5;
  flex( row, nowrap, center, center);
}
.messageContent{
  font-size: $Fts5;
  opacity: 0;
  min-width: 2.8rem;
  max-width: $maxWidth;
  text-align: center;
  padding: .16rem .2rem;
  background-color: $Cf;
  border-radius(.06rem);
  box-sizing: border-box;
  box-shadow( 0, 0, .1rem, #e1e1e1);  
  margin-bottom: .3rem;
}
.show{
  animation:showKey 2.5s;
  -webkit-animation:showKey 2.5s; 
  animation-fill-mode: forwards; 
}
.none{
  // display: none;
}
@keyframes showKey
{
  from {
  }
  6% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes showKey /*Safari and Chrome*/
{
  from {
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }

}

</style>