import Main from './main.vue';
const ShareConstructor = Vue.extend(Main);

let instance;

const Message = function(options) {
    instance = new ShareConstructor({
      data: options
    });
    instance.visible = true;
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.close = function () {
      var message = document.getElementsByClassName("libMessage")[0];
      message && document.body.removeChild(message);
      instance.visible = false;
    }
};

export default Message;
